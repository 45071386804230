<template>
  <div>
    <ScreenLoading v-if="isFetchingPage" transition="fade" />
    <div v-if="userData">
      <div id="header-container">
        <Header
          :logo-image="userData.campaign_settings.logo_image_url"
          :bg-color-logo-image="userData.campaign_settings.preset_logo_bg_color"
        />
      </div>
      <div id="body-container">
        <router-view
          :campaign-url="userData.campaign_url"
          :campaign-url-name="userData.campaign_url_name"
          :campaign-infos="userData.campaign_infos"
          :campaign-notes="userData.campaign_notes"
          :campaign-settings="userData.campaign_settings"
          :campaign-country="userData.campaign_country"
          @fetching-page="fetchingPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ScreenLoading, Header } from '@components'
import { Campaign } from '@utils'

export default {
  name: 'GifteeCPLayout',
  components: {
    ScreenLoading,
    Header
  },
  data() {
    return {
      isFetchingPage: false,
      userData: null,
      selectedLanguage: 0
    }
  },
  created() {
    const data = Campaign.getCampaign()

    this.userData = {
      campaign_url: data.campaign_url,
      campaign_url_name: data.campaign_url_name,
      campaign_infos: data.campaign_infos,
      campaign_notes: data.campaign_notes,
      campaign_settings: data.campaign_settings,
      campaign_country: data.campaign_country
    }
  },
  methods: {
    fetchingPage: (isFetchingPage) => {
      this.isFetchingPage = isFetchingPage
    },
    loadLanguages: function(languages) {
      this.languages = languages
    }
  }
}
</script>

<style lang="scss" scoped></style>
